import getEnvironmentFromUrl from './utils/url'

type AllEnvTypes = 'all' | 'qa' | 'qa1' | 'preprod' | 'prod' | 'local' | 'mocks'

const hostname = typeof window !== 'undefined' ? window.location.hostname : ''
const currentEnvironment = getEnvironmentFromUrl(
  hostname,
  'order',
  'quotatis.com',
)

const configs = {
  all: {
    env: (currentEnvironment as AllEnvTypes) || 'development',
    sentry: {
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.5,
      url: 'https://9c54b19fc4e5bf41daad446bf9d8dfcb@o88364.ingest.us.sentry.io/4507090930171904',
    },
    api: {
      url: 'https://b2b-gw.qa.quotatis.com',
      apiKey: 'c6d37388cdb7a0c7bca5076481393b80',
    },
    cloudinary: {
      url: 'https://res.cloudinary.com/quotatis/image/upload',
      app: 'FrontApp',
      api: 'https://api.cloudinary.com/v1_1',
      cloudName: 'quotatis',
      apiSecret: 'HOJZMYzlYfL5CnsOU-b0DiKS6Sk',
      apiKey: '758912458653982',
    },
    auth0: {
      domain: 'order-qa.eu.auth0.com',
      clientId: 'wmv5rU6IKSBSM5ra93HsWQOHm0yjsb4G',
      audience: 'https://auth0.order.qa.quotatis.com/logical-api',
    },
    url: '',
    sdpUrl: '',
  },
  qa1: {
    url: 'https://order.qa1.quotatis.com',
    sdpUrl: 'https://qa1.suividepose.fr',
    api: {
      url: 'https://b2b-gw.qa1.quotatis.com',
      apiKey: 'bbd1a79c215cd579c414d3715c7136a8',
    },
    auth0: {
      domain: 'order-qa1.eu.auth0.com',
      clientId: 'fxBIGCDiBDn6PekvfU8cpBS1ubFhIsIY',
      audience: 'https://auth0.order.qa1.quotatis.com/logical-api',
    },
  },
  qa: {
    url: 'https://order.qa.quotatis.com',
    sdpUrl: 'https://qa.suividepose.fr',
  },
  preprod: {
    url: 'https://order.preprod.quotatis.com',
    sdpUrl: 'https://preprod.suividepose.fr',
    api: {
      url: 'https://b2b-gw.preprod.quotatis.com',
      apiKey: 'e9661cb16bc5e1c783b1db6cc8cdda84',
    },
    auth0: {
      domain: 'order-preprod.eu.auth0.com',
      clientId: 'DdtyrCwvUuI8HL2ILQMqB0kEUBgj7ghb',
      audience: 'https://auth0.order.preprod.quotatis.com/logical-api',
    },
  },
  prod: {
    url: 'https://order.quotatis.com',
    sdpUrl: 'https://www.suividepose.fr',
    api: {
      url: 'https://b2b-gw.prod.quotatis.com',
      apiKey: 'b604c257955ed8e2177932cdc4491666',
    },
    auth0: {
      domain: 'order-prod.eu.auth0.com',
      clientId: 'SJvY77gynBmGqihFnsZZ3rQZOsOKoewF',
      audience: 'https://auth0.order.prod.quotatis.com/logical-api',
    },
    sentry: {
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
      url: 'https://9c54b19fc4e5bf41daad446bf9d8dfcb@o88364.ingest.us.sentry.io/4507090930171904',
    },
  },
  local: {
    url: 'https://order.local.quotatis.com:4433',
    sdpUrl: 'https://qa.suividepose.fr',
  },
  localqa1: {
    url: 'https://order.localqa1.quotatis.com:4433',
    sdpUrl: 'https://qa1.suividepose.fr',
    api: {
      url: 'https://b2b-gw.qa1.quotatis.com',
      apiKey: 'bbd1a79c215cd579c414d3715c7136a8',
    },
    auth0: {
      domain: 'order-qa1.eu.auth0.com',
      clientId: 'fxBIGCDiBDn6PekvfU8cpBS1ubFhIsIY',
      audience: 'https://auth0.order.qa1.quotatis.com/logical-api',
    },
  },
  mocks: {},
}

const currentEnv: AllEnvTypes = configs.all.env
const currentConfig = configs[currentEnv]
const config = { ...configs.all, ...currentConfig }

export default config
