import auth0 from 'auth0-js'
import config from '@/config'

type Auth0Config = {
  domain: string
  clientId: string
  audience: string
}
class Auth0 {
  private _auth: auth0.WebAuth

  private _auth0conf: Auth0Config

  private _clientName: string

  constructor(clientName: string) {
    this._auth0conf = config.auth0
    this._clientName = clientName
    this._auth = new auth0.WebAuth({
      domain: config.auth0.domain,
      clientID: config.auth0.clientId,
      responseType: 'token id_token',
      scope: 'openid email offline_access',
      redirectUri: `${config.url}/login`,
    })
  }

  get auth(): auth0.WebAuth {
    return this._auth
  }

  set auth(value: auth0.WebAuth) {
    this._auth = value
  }

  get auth0conf(): Auth0Config {
    return this._auth0conf
  }

  set auth0conf(value: Auth0Config) {
    this._auth0conf = value
  }

  get clientName(): string {
    return this._clientName
  }

  set clientName(value: string) {
    this._clientName = value
  }

  async passwordlessStart(credential: { email: string }) {
    return new Promise((resolve, reject) => {
      this.auth.passwordlessStart(
        {
          email: credential.email,
          connection: 'email',
          send: 'link',
          authParams: {
            audience: this._auth0conf.audience,
            scope: 'openid email offline_access',
          },
        },
        (err, response) => {
          if (err) {
            const { original, ...error } = err
            reject(error)
            return
          }

          resolve(response)
        },
      )
    }).catch((error: Error) => error)
  }

  async checkSession() {
    return new Promise((resolve, reject) => {
      this.auth.checkSession(
        {
          audience: this._auth0conf.audience,
          scope: 'openid email',
        },
        (err, response) => {
          if (err) {
            const { original, ...error } = err
            reject(error)

            return
          }

          resolve(response)
        },
      )
    }).catch((error: Error) => error)
  }

  logout() {
    this.auth.logout({
      clientID: this._auth0conf.clientId,
      returnTo: `${config.url}/login`,
    })
  }
}

const auth0Partner = new Auth0('partner')

export default auth0Partner
